import { nextTick } from 'vue';
import { useRootStore } from '@/store';
import HelloWorld from '@/components/HelloWorld.vue';
import VehicleType from '@/enums/VehicleType';
import VueRouter from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HelloWorld,
  },
  {
    path: '/airships/builds-calculator',
    name: 'AirshipBuildsCalculator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipBuildsCalculatorPage.vue'),
    props: {
      vehicleType: VehicleType.AIRSHIP,
    },
  },
  {
    path: '/airships/leveling-calculator',
    name: 'AirshipExpCalculator',
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipExpCalculatorPage.vue'),
  },
  {
    path: '/airships/parts',
    name: 'AirshipPartsList',
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipPartsListPage.vue'),
  },
  {
    path: '/airships/ranks',
    name: 'AirshipRanksList',
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipRanksListPage.vue'),
  },
  {
    path: '/airships/sectors',
    name: 'AirshipSectorsList',
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipSectorsListPage.vue'),
  },
  {
    path: '/submarines/builds-calculator',
    name: 'SubmarineBuildsCalculator',
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineBuildsCalculatorPage.vue'),
    props: {
      vehicleType: VehicleType.SUBMARINE,
    },
  },
  {
    path: '/submarines/leveling-calculator',
    name: 'SubmarineExpCalculator',
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineExpCalculatorPage.vue'),
  },
  {
    path: '/submarines/parts',
    name: 'SubmarinePartsList',
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarinePartsListPage.vue'),
  },
  {
    path: '/submarines/ranks',
    name: 'SubmarineRanksList',
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineRanksListPage.vue'),
  },
  {
    path: '/submarines/sectors',
    name: 'SubmarineSectorsList',
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineSectorsListPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(
  async (_to, _from, next) => {
    const store = useRootStore();
    // Show Loading
    store.setLoading(true);
    await nextTick();

    // @see https://github.com/championswimmer/vuex-persist#how-to-know-when-async-store-has-been-replaced
    // await store.restored;

    next();
  }
);

router.afterEach(() => {
  const store = useRootStore();
  // Hide Loading
  store.setLoading(false);
});

export default router;
