<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-flex d-sm-none"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        </div>

      <div class="d-none d-sm-flex">
        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              text
            >
              <span class="mr-2">Airships</span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="item in menus[0].children" :key="item.text">
              <v-list-item-title>
                <v-btn style="width: 100%" tag="router-link" :to="{ path: item.link }" text>
                  <span class="mr-2">{{ item.text }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        
        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              text
            >
              <span class="mr-2">Submarines</span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="item in menus[1].children" :key="item.text">
              <v-list-item-title>
                <v-btn style="width: 100%" tag="router-link" :to="{ path: item.link }" text>
                  <span class="mr-2">{{ item.text }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>

<!--       <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>fas fa-external-link-alt</v-icon>
      </v-btn> -->
      <UseDark storageKey="isDarkTheme" v-slot="{ toggleDark }">
        <v-btn icon text @click="toggleDark()">
          <v-icon v-if="isDark">mdi-lightbulb-on</v-icon>
          <v-icon v-else>mdi-lightbulb-off</v-icon>
        </v-btn>
      </UseDark>
      <LanguageMenu></LanguageMenu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      absolute
    >
    <v-list dense>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Airships</v-list-item-title>
          </template>
          <v-list-item v-for="item in menus[0].children" :key="item.text" link tag="router-link" :to="{ path: item.link }">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Submarines</v-list-item-title>
          </template>
          <v-list-item v-for="item in menus[1].children" :key="item.text" link tag="router-link" :to="{ path: item.link }">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title link tag="router-link" :to="{ path: '' }">Useful links</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title link tag="router-link" :to="{ path: '' }">Support us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title link tag="router-link" :to="{ path: '' }">Discord</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <RouterView/>
    </v-main>

    <v-overlay v-show="loading" z-index="999">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <v-snackbar
      v-model="snackbar"
      app
      timeout="5000"
      transition="scroll-y-transition"
    >
      {{ snackbarText }}
      <template #action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useRootStore } from '@/store';
import { useVuetify } from './plugins/vuetify';
import { useDark } from '@vueuse/core';
import { UseDark } from '@vueuse/components';
import LanguageMenu from '@/components/menus/LanguageMenu.vue';

export default defineComponent({
  components: {
    LanguageMenu,
    UseDark,
  },
  setup() {
    const rootStore = useRootStore();
    const route = useRoute();
    const vuetify = useVuetify();
    const isDark = useDark({
      storageKey: 'isDarkTheme',
      onChanged: (dark) => {
        vuetify.theme.dark = dark;
      },
    });

    const drawer = ref(false);
    const snackbar = ref(false);
    const menus = ref([
      {
        icon: '',
        text: 'Airships',
        children: [
          {
            icon: '',
            text: 'Builds calculator',
            link: '/airships/builds-calculator',
          },
          {
            icon: '',
            text: 'Leveling calculator',
            link: '/airships/leveling-calculator',
          },
          {
            icon: '',
            text: 'Parts',
            link: '/airships/parts',
          },
          {
            icon: '',
            text: 'Ranks',
            link: '/airships/ranks',
          },
          {
            icon: '',
            text: 'Sectors',
            link: '/airships/sectors',
          },
        ],
      },
      {
        icon: '',
        text: 'Submarines',
        children: [
          {
            icon: '',
            text: 'Builds calculator',
            link: '/submarines/builds-calculator',
          },
          {
            icon: '',
            text: 'Leveling calculator',
            link: '/submarines/leveling-calculator',
          },
          {
            icon: '',
            text: 'Parts',
            link: '/submarines/parts',
          },
          {
            icon: '',
            text: 'Ranks',
            link: '/submarines/ranks',
          },
          {
            icon: '',
            text: 'Sectors',
            link: '/submarines/sectors',
          },
        ],
      },
      {
        icon: '',
        text: 'Useful links',
        link: '',
      },
      {
        icon: '',
        text: 'Support us',
        link: '',
      },
      {
        icon: '',
        text: 'Discord',
        link: '',
      },
    ]);

    const snackbarText = computed({
      get: () => rootStore.message,
      set: (message) => rootStore.setMessage(message),
    });
    const loading = computed({
      get: () => rootStore.loading,
      set: (isLoading) => rootStore.setLoading(isLoading),
    });

    onMounted(() => rootStore.setLoading(false));

    watch(() => route?.name, () => {
      snackbar.value = false;
      snackbarText.value = '';
    });
    watch(loading, (isLoading) => document.body.style.cursor = isLoading ? 'wait' : 'auto');
    watch(snackbarText, (value) => snackbar.value = value !== '');

    return {
      rootStore,
      snackbarText,
      loading,
      snackbar,
      drawer,
      menus,
      isDark,
    };
  },
});
</script>
