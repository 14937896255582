import { ref } from 'vue';
import { defineStore } from 'pinia'

export const useRootStore = defineStore('root', () => {
  const message = ref('');
  const loading = ref(false);

  const setMessage = (value) => {
    message.value = value;
  };

  const setLoading = (isLoading) => {
    loading.value = isLoading;
  };

  return {
    // state
    message,
    loading,
    // actions
    setMessage,
    setLoading,
  };
});
