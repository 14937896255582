import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';

import en from '@/i18n/messages/en.json';
import ja from '@/i18n/messages/ja.json';

Vue.use(VueI18n)

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, ja },
});
