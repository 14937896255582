<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <div class="my-5" v-for="link of links" :key="link.text">
          <v-btn
            small
            tag="a"
            :href="link.url"
            width="100%"
            :color="link.color"
          >
            {{ link.text }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data() {
      return {
        links: [
          {
            text: "Pepper's FFXIV Ship Guide",
            url: 'https://docs.google.com/document/d/1t2cC8-VuSNU2JLrvFFvr1S0-7NirnJiJ3k41UJ56jqE/edit',
            color: 'primary',
          },
          {
            text: "[VIDEO] Beginner's Guide to Submarines",
            url: 'https://www.youtube.com/watch?v=sN2pYp2r7Xs',
            color: 'primary',
          },
          {
            text: 'FFXIV Submarine Builders Discord',
            url: 'https://discord.gg/GAVegXNtwK',
            color: 'warning',
          },
          {
            text: 'Mogship Discord',
            url: 'https://discord.gg/PUkcQH8nFh',
            color: 'secondary',
          },
        ],
      };
    },
  }
</script>
