import axios from 'axios';
import Vue from 'vue';

const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:8000';

export const HttpClient = axios.create({
  baseURL: apiUrl,
});

export default {
  install() {
    const http = axios.create({
      baseURL: apiUrl,
    });
    http.interceptors.request.use((config) => {
      const newConfig = { ...config };
      const token = sessionStorage.getItem('token');
      if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
  
      return newConfig;
    }, (error) => Promise.reject(error));
    
    
    Vue.prototype.$http = http;
  }
};
