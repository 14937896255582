import Vue from 'vue'
import App from './App.vue'
import http from '@/http';
import router from '@/router';
import vuetify from '@/plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import createI18n from '@/i18n';
import VueRouter from 'vue-router';

Vue.use(PiniaVuePlugin)
const pinia = createPinia();

Vue.use(VueRouter);

Vue.config.productionTip = false

Vue.use(http);

const i18n = createI18n;

new Vue({
  i18n,
  pinia,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
